(function(app){
	'use strict';

	app.component('frtTaskActivities', {
			templateUrl: '/js/components/frtTaskActivities/task-activities.html',
			bindings: {
				grid: '<',
				manualPoints: '<',
				allowed: '=',
				x: '<',
				free: "=",
                skip: "=",
                standActivitiesPhotosNumber: "=",
                standRowLength: "=",
                form: "="
			},
			controllerAs: 'vm',
			controller: TaskActivitiesController
		}
	);

	function TaskActivitiesController ($scope, _i18n) {
		var vm = this;
		vm.sort = ['NOTE', 'INSECTS', 'DISEASES', 'WEEDS', 'STAND', 'FENOLOGY'];
		vm.a = {}; //sliders model
		Object.defineProperties(vm.a, {
			STAND: {
				get: function() {
					return vm.allowed.STAND;
				},
				set: function(newVal) {
					vm.allowed.STAND = newVal;
					recalculateAllActivities();
				},
				enumerable: true
			},
			DISEASES: {
				get: function() {
					return vm.allowed.DISEASES;
				},
				set: function(newVal) {
					vm.allowed.DISEASES = newVal;
					recalculateAllActivities();
				},
				enumerable: true
			},
			INSECTS: {
				get: function() {
					return vm.allowed.INSECTS;
				},
				set: function(newVal) {
					vm.allowed.INSECTS = newVal;
					recalculateAllActivities();
				},
				enumerable: true
			},
			FENOLOGY: {
				get: function() {
					return vm.allowed.FENOLOGY;
				},
				set: function(newVal) {
					vm.allowed.FENOLOGY = newVal;
					recalculateAllActivities();
				},
				enumerable: true
			},
			WEEDS: {
				get: function() {
					return vm.allowed.WEEDS;
				},
				set: function(newVal) {
					vm.allowed.WEEDS = newVal;
					recalculateAllActivities();
				},
				enumerable: true
			},
            NOTE: {
                get: function() {
                    return vm.allowed.NOTE;
                },
                set: function(newVal) {
                    vm.allowed.NOTE = newVal;
                    recalculateAllActivities();
                },
                enumerable: true
            }
		});

		var map = vm.allowed;
		Object.defineProperties(vm, {
			allowed: {
				get: function() {
					return map;
				},
				set: function(newVal) {
					map = newVal;
					recalculateAllActivities();
				}
			}
		});

		function getIndexes(total, k) {
			var result = [];
			if (k < 0 || k > 1 || total < 1) {
				return result;
			}

			var num = Math.ceil(total * k);
			var a = _.range(total);

			while (result.length < num) {
				result.push(a.splice(_.random(a.length-1), 1)[0]);
			}

			return result;
		}

		var recalculateAllActivities = function() {

			if (vm.allowed) {
                var stand = vm.a.STAND;
                var diseases = vm.a.DISEASES;
                var insects = vm.a.INSECTS;
                var fenology = vm.a.FENOLOGY;
                var weeds = vm.a.WEEDS;
                var regular_note = vm.a.NOTE;

				if (_.size(vm.manualPoints) > 0) {
                    var pointsNum = _.size(vm.manualPoints);
                    var standIndexes = getIndexes(pointsNum, stand);
                    var diseasesIndexes = getIndexes(pointsNum, diseases);
                    var insectsIndexes = getIndexes(pointsNum, insects);
                    var fenologyIndexes = getIndexes(pointsNum, fenology);
                    var weedsIndexes = getIndexes(pointsNum, weeds);
                    var regular_noteIndexes = getIndexes(pointsNum, regular_note);

                    var pointIndex = 0;
                    vm.manualPoints.forEach(point => {
                        var pointActivities = {};
                        pointActivities['STAND'] = {value: _.contains(standIndexes, pointIndex)};
                        pointActivities['DISEASES'] = {value: _.contains(diseasesIndexes, pointIndex)};
                        pointActivities['INSECTS'] = {value: _.contains(insectsIndexes, pointIndex)};
                        pointActivities['FENOLOGY'] = {value: _.contains(fenologyIndexes, pointIndex)};
                        pointActivities['WEEDS'] = {value: _.contains(weedsIndexes, pointIndex)};
                        pointActivities['NOTE'] = {value: _.contains(regular_noteIndexes, pointIndex)};

                        point.activities = pointActivities;
                        pointIndex++;
                    });

				} else if (vm.grid) {

                    var pointsNum = 0;
                    vm.grid.pathLines.forEach(pathline => {
                        pointsNum = pointsNum + _.size(pathline.scoutPoints);
                    });

                    var standIndexes = getIndexes(pointsNum, stand);
                    var diseasesIndexes = getIndexes(pointsNum, diseases);
                    var insectsIndexes = getIndexes(pointsNum, insects);
                    var fenologyIndexes = getIndexes(pointsNum, fenology);
                    var weedsIndexes = getIndexes(pointsNum, weeds);
                    var regular_noteIndexes = getIndexes(pointsNum, regular_note);

                    var pointIndex = 0;
                    vm.grid.pathLines.forEach(pathline => {
                        pathline.scoutPoints.forEach(point => {
                            var pointActivities = {};
                            pointActivities['STAND'] = {value: _.contains(standIndexes, pointIndex)};
                            pointActivities['DISEASES'] = {value: _.contains(diseasesIndexes, pointIndex)};
                            pointActivities['INSECTS'] = {value: _.contains(insectsIndexes, pointIndex)};
                            pointActivities['FENOLOGY'] = {value: _.contains(fenologyIndexes, pointIndex)};
                            pointActivities['WEEDS'] = {value: _.contains(weedsIndexes, pointIndex)};
                            pointActivities['REGULAR'] = {value: _.contains(regular_noteIndexes, pointIndex)};

                            point.activities = pointActivities;
                            pointIndex++;
                        });
                    });

                }
            }
		};

		vm.activities = {};
		vm.slider = {};

		_.each(vm.a, (activity, i) => {
			$scope.$watch('vm.a.' + i, value => {
				vm.activities[i] = Math.round(value * 100) + '%';
			});

			vm.slider[i] = {
				options: {
					floor: 0,
					ceil: 1,
					step: 0.01,
					precision: 2,
					hideLimitLabels: true,
					hidePointerLabels: true,
					onChange: () => {
						vm.activities[i] = Math.round(vm.a[i] * 100) + '%';
					}
				}
			};
		});

		vm.getLabelOf = function(key) {
			return _i18n.getString('task.activity.' + key);
		}

	}
}) (angular.module('app'));
